<template>
  <div class="vz-period-once">
    <div class="flex flex-align-items-center flex-nowrap">
      <label for="" class="mb-0 mr-10 text-body">с</label>
      <vz-datepicker
        v-model="modelValue.start"
        :max-date="fromMaxDate"
        :width="110"
        @change="onChangeStartDate"
        :big="big"
      />
    </div>
    <div class="flex flex-align-items-center flex-nowrap">
      <label for="" class="mb-0 mr-10 text-body">до</label>
      <vz-datepicker
        v-model="modelValue.end"
        :width="110"
        :max-date="toMaxDate"
        @change="onChangeEndDate"
        :big="big"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue'
import {useDayjs} from "#dayjs";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Object as PropType<{ start: string, end: string }>,
    default: () => ({start: '', end: ''})
  },
  big: {
    type: Boolean,
    default: false
  }
})

const dayjs = useDayjs()
const isEndDateAfterStart = computed((): boolean => {
  const from = dayjs(props.modelValue.start)
  const to = dayjs(props.modelValue.end)

  if (to.diff(from) < 0) {
    return true
  }
  return false
})

const fromMaxDate = computed((): string => {
  return dayjs().subtract(1, 'day').format('YYYY-MM-DD')
})

const toMaxDate = computed((): string => {
  return dayjs().format('YYYY-MM-DD')
})

function onChangeEndDate(date: string): void {
  if (isEndDateAfterStart.value) {
    emit('update:modelValue', {start: props.modelValue.start, end: props.modelValue.start})
    return
  }
  emit('update:modelValue', {
    start: props.modelValue.start,
    end: date
  })
}

function onChangeStartDate(date: string): void {
  if (isEndDateAfterStart.value) {
    emit('update:modelValue', {start: props.modelValue.start, end: props.modelValue.start})
    return
  }
  emit('update:modelValue', {
    start: date,
    end: props.modelValue.end
  })
}
</script>

<style lang="scss" scoped>
.vz-period-once {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
