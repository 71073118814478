<template>
  <div class="vz-email-list">
    <div v-for="(email, index) of localValue">
      <vz-input
        :key="index"
        v-model="email.value"
        label="E-mail"
        required
        big
        :label-public-style="labelPublicStyle"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
        @change="updateList"
      >
        <template #additional-label>
          <vz-label
              v-if="hasAddButton(index)"
              class="vz-email-list-add"
              :class="{'fs-14 mt-5': labelPublicStyle}"
              without-dot
              variant="primary"
              v-text="'+ Доп. почта'"
              @click.stop.prevent="add"
          />
        </template>
      </vz-input>
      <vz-icon
        v-if="index > 0"
        class="vz-email-list-close-icon"
        :class="{'public-style': labelPublicStyle}"
        size="13"
        name="close"
        :disabled="disabled"
        @click="remove(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import {ValidationRule} from '~/types/types';

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  labelPublicStyle: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  maxLength: {
    type: Number,
    default: 3
  },
})

const localValue = ref<{ value: string }[]>([])

function add() {
  localValue.value.push({value: ''})
}

function updateList() {
  emit('update:modelValue', localValue.value.map(item => item.value))
}

function remove(index: number) {
  localValue.value = localValue.value.filter((_, i) => i !== index)
  updateList()
}

function hasAddButton(index: number) {
  if (props.disabled) {
    return false
  }
  return (index < props.maxLength - 1) && (index === localValue.value.length - 1)
}

watch(() => props.modelValue, () => {
  localValue.value = props.modelValue?.length
    ? props.modelValue.map(item => ({ value: item }))
    : [''].map(item => ({ value: item }))
}, { immediate: true, deep: true })
</script>

<style lang="scss" scoped>
.vz-email-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    position: relative;
    display: flex;
    flex: 1 1 100%;
  }

  &-close-icon {
    position: absolute;
    right: 5px;
    top: 35px;
    background: $component-background;
    border-radius: 50%;
    cursor: pointer;
    color: $font-light;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;

    &.public-style {
      top: 38px;
    }
  }

  &-add {
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}
</style>